/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 20, 2023 */



@font-face {
    font-family: 'helveticaregular';
    src: url('helvetica-webfont.woff2') format('woff2'),
         url('helvetica-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticabold';
    src: url('helvetica-bold-webfont.woff2') format('woff2'),
         url('helvetica-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticabold_oblique';
    src: url('helvetica-boldoblique-webfont.woff2') format('woff2'),
         url('helvetica-boldoblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticacompressed';
    src: url('helvetica-compressed-5871d14b6903a-webfont.woff2') format('woff2'),
         url('helvetica-compressed-5871d14b6903a-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticaoblique';
    src: url('helvetica-oblique-webfont.woff2') format('woff2'),
         url('helvetica-oblique-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'helveticarounded_bold';
    src: url('helvetica-rounded-bold-5871d05ead8de-webfont.woff2') format('woff2'),
         url('helvetica-rounded-bold-5871d05ead8de-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suez_oneregular';
    src: url('suezone-regular-webfont.woff2') format('woff2'),
         url('suezone-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'reklame_script_demoregular';
    src: url('reklamescript-regular_demo-webfont.woff2') format('woff2'),
         url('reklamescript-regular_demo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'reklame_scriptmedium';
    src: url('reklame_script_medium-webfont.woff2') format('woff2'),
         url('reklame_script_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
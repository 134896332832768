/* responsive media css start here */
@media only screen and (max-width: 1399px) {
    .footer_section .col-4 {width: 40.333333%;}
}
@media only screen and (max-width: 1280px) {
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 21.666667%;}
    .paymentforms.paymentFormsWrapper {max-height: calc(100vh - 320px) !important;overflow: auto;}
}
@media only screen and (max-width: 1201px) {
    .logogheader .logohere img {width: 190px;height: 190px;}
    .logogheader .logohere {margin: 0px;}
    .Header_section {padding: 5px 0px;}
    .container.pravcypolicycontainer {float: none;}
    .container {float: left; width: 100%; max-width: 100%;}
    .row {float: none;display: table;margin: 0 auto;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 24.666667%;}
    .chosseCustomNumber {width: 100%;}
    .choosebundleModal h1 {font-size: 38px;}
}
@media only screen and (max-width: 1150px) {
    .Header_section .container-fluid{padding: 0px 10px;}
    .Header_section .container-fluid .col-md-12 {padding: 0px;}
}
@media only screen and (max-width: 1100px) {
    .wrapper {padding: 0px;}
    header.Header_section.Header_after_login .userLoginSignupwraps .userLoginSignupwrapsrow {justify-content: center;}
    header.Header_section.Header_after_login .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx{width: auto;}
    header.Header_section.Header_after_login .userLoginSignupwraps .userLoginSignupwrapsrow.userLoginSignupwrapsrowcenter .loginbtnbx {width: 120px;}
    header.Header_section.Header_after_login .logogheader .logohere {margin-right: 10px;}
    .logogheader .userLoginSignupwraps:last-child {left: 0;right: 0;}
    .logogheader {flex-direction: column;}
    .userLoginSignupwraps .userLoginSignupwrapsrow {align-items: center;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx span {font-size: 13px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx h1 {font-size: 25px;}
    .logogheader .logohere img {width: 100px;height: 100px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx img {width: 75px;height: 65px;}
    .Header_section {position: relative;padding: 6px 0px;}
    .logogheader .userLoginSignupwraps:nth-child(2) {left: auto;}
}
@media only screen and (max-width: 1021px) {
    .header_section .logowraphead img {width: 170px;}
}
@media only screen and (max-width: 991px) {
    .redeempics {height: 295px;overflow: auto;flex-direction: column;}
    .redeemprizewrapper .col-3 {width: 25%;}
    .RedeemCreditModalMain {width: 86%;}
    .myacountsatabswrps .row .col-md-3 {width: 40%;}
    .myacountsatabswrps .row .col-md-9 {width: 60%;}
    .redeemprizewrapper img {width: 100%;height: auto;}
    .redeemprizewrapper .col-4 {width: 25%;}
    .redeemformadd {margin-bottom: 20px;}
    .redeemprizebest .col-md-6 {width: 100%;}
    .checkoutpaymnt {width: 100%;padding: 0px;}
    .headermainwrps {padding: 0px;}
    .headerLinks ul li a {font-size: 21px;}
    .header_section .logowraphead img {width: 135px;}
    .commonheader h1 {font-size: 36px;}
    .commonheader h4 {font-size: 64px;}
    .purchesswraps {float: left;width: 100%;}
    .header_section .logowraphead{width: auto;}
    .footer_section .col-md-5 {width: 50%;}
    .footer_section .col-4 {width: 50%;}
    .videoModalwrapper .videoplayer iframe {width: 600px;height: 380px;}
}
@media only screen and (max-width: 900px) {
    .bannerssections h4 {font-size: 40px;line-height: 50px;}
    .row {display: block;}
    .bookspreviewModal .closePreviewModal {width: 22px;height: 22px;right: 15px;top: 15px;}
    .bookspreviewModal {width: 100%;padding: 20px 15px;}
    .PurchesPostersction .purchesswraps .col-6 {width: 100%;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12 {width: 32.666667%;padding: 0px 10px !important;}
    .purchespassage {margin-bottom: 50px;}
    .logogheader h1 {font-size: 30px;}
    .Header_section {padding: 10px 0px;}
}
@media only screen and (max-width: 840px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .landingfixedpage video {width: 80%;height: 390px;}
    .chosseCustomNumber {width: 100%;display: block;}
    .chosseCustomNumber .formgroup {width: 48%;float: left;margin-bottom: 15px;}
    .aboutgamessection {padding: 40px 0 40px;}
    .aboutgamessection h5 {font-size: 17px;}
    .aboutgamessection h1 {font-size: 35px;}
    .aboutparagraphsection {height: auto;}
    .aboutparagraphsection .col-md-4 , .aboutparagraphsection .col-md-8{width: 100%;}
    .aboutparagraphsection p {width: 100%;padding: 25px 25px;}
    .aboutgamessection .col-md-7 {width: 100%;}
    .whtweareaboutsection h1 {font-size: 35px;}
    .whtweareaboutsection {padding: 40px 0;}
    .meetfounderssectionsww h4 {font-size: 35px;}
    .ourstudiosection {padding: 90px 0;}
    .ourstudiodata {width: 100%;}
    .ourstudiodata h1 {font-size: 35px;}
    .welovworkthemsection {width: 100%;}
}
@media only screen and (max-width: 800px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .CommonDropdownWraps {top: 60px;left: 18px;}
    .loginwrapsbtn h5 {margin: 0 12px 0 12px;}
    .btn.resetbtn {padding: 8px 9px;font-size: 15px;width: auto;}
    .searchfilterbookswraps select {width: 260px;height: 41px;padding: 5px;font-size: 14px;}
    .searchfilterbookswraps h4{font-size: 17px;}
    .sectionheader {font-size: 30px;}
    .header_section .logowraphead {width: auto !important;float: none;margin: 0 auto;}
    .dropdousericon {margin: 0px 18px -3px 0px !important;}
    .responsiveSidebar {padding: 25px 15px;}
}
@media only screen and (max-width: 767px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .myacountsatabswrps .row .col-md-3 {padding: 0px 5px;}
    .myacountsatabswrps .row .col-md-9 {padding: 0 5px;}
    .redeemprice_wrapper {padding: 18px 6px;}
    .redeemprizewrapper .col-3 {padding: 0px 5px;}
    .myacountsatabswrps .row .col-md-3 {margin-bottom: 15px;}
    .bannerssections h4 {font-size: 29px;line-height: 36px;}
    .bannerssections img {width: 358px;}
    .myacountsatabswrps .row .col-md-3, .myacountsatabswrps .row .col-md-9 {width: 100%;}
    .ourstudiosection {padding: 40px 0;}
    .ourstudiodata {margin-bottom: 70px;}
    .whtweareaboutcols h2 {font-size: 22px;margin: 0 0 15px;}
    .meetfounderssectionsww {padding: 35px 30px 35px;}
    .copyright {padding: 25px 0px 25px;}
    .contactlinks {margin-bottom: 30px;}
    .contactlinks h4 {font-size: 18px;margin: 0 0 10px;}
    .footwrapper {padding: 25px 0px 30px;}
    .gamesonlinewrps iframe {width: 100%;height: 320px;}
    .gamelistwraps {border-radius: 45px;display: flex;align-items: center;justify-content: center;}
    .gamelistwraps h4 {width: 76%;bottom: auto;font-size: 45px;word-break: break-word;}
    .GamesGridwrapper .col-md-3.col-xs-6 {width: 50%;}
    .GamesGridwrapper {margin: 0;padding: 45px 0;}
    .closeModal {z-index: 99;}
    .paymentheadermain {margin: 6px 0 13px;}
    .pymenttitle {margin: 0 0 20px;padding: 13px 46px 0px 20px;}
    .CheckoutModalWrapper {padding: 0px;width: 100%;}
    .CheckoutModalWrapper .paymentWrappers {padding: 0px 20px;}
    .paymentheadermain h4 {padding-left: 20px;}
    .paymentforms {max-height: calc(100vh - 245px) !important;}
    .dropdowncustome .amountdetailsdropdown {right: -15px;}
    .paymentforms.paymentFormsWrapper {max-height: calc(100vh - 245px) !important;}
    .myaccountprofilepic h4 {font-size: 23px;text-align: left;}
    .myaccountprofilepic {height: auto;padding: 25px 33px;}
    .acctprofile {float: left;width: 120px;height: 120px;margin-bottom: 20px;background-color: #fff;}
    .checkoulistwrps {width: 100%;margin-bottom: 40px;}
    .profileacountset {float: left;width: 100%;text-align: left;margin: 80px 0 0;}
    .checkoutpaymnt h1 {font-size: 34px;margin: 0 0 20px;}
    .singlepostersection.PosterBundleswraper .purchesBundletypes {width: 100%;}
    .singlepostersection.PosterBundleswraper .purchesswraps .col-md-6:last-child .purchesBundletypes {margin: 0px;}
    .commonheader h1 {font-size: 33px;line-height: 55px;}
    .commonheader h4 {font-size: 50px;}
    .gamelistwraps {height: auto;}
    .row {display: block;}
    .contadetails h4 {font-size: 18px;margin: 0 0 10px;}
}
@media only screen and (max-width: 700px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .responsiveSidebar .headerLinks ul li.active a {color: rgb(218, 57, 43);}
    .commonpopup {width: 95%;padding: 40px 32px;}
    .footer_section .col-md-5, .footer_section .col-4 {width: 100%;}
    .footermailjoin p {margin: 20px 0 20px;}
    .choosebundleModal h1 {font-size: 28px;margin: 0 0 45px;}
    .chosseCustomNumber .formgroup .CustomRadio { font-size: 22px;}
    .privacypage.RefundPolicypage {padding: 40px 0px;}
    .bannerSectionWrp video {height: 430px;}
    .bannervideocontent {padding: 16px 0;height: 100%;display: flex;justify-content: center;align-items: center;}
    .bannerssections {position: relative;}
    .bannerSectionWrp video {height: auto;}
    .bannerssections img {width: 300px;margin-bottom: 0px;z-index: 9;}
    .bannerbtn, .bannerbtn:hover, .bannerbtn:focus {position: relative;z-index: 9;}
    .bannerbtn::after {content: "";float: left;width: 100%;height: 100%;border: 1px solid #DBAD47;background-color: #DBAD47;position: absolute;right: -69px;top: 0px;z-index: -9;}
}
@media only screen and (max-width: 640px) {   
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .landingfixedpage video {width: 95%;height: 390px;}
    .videoModalwrapper .videoplayer iframe {width: 95%;}
    .contactDetails {margin: 0 0 30px;}
    .privacypage h1 {font-size: 25px;margin: 0px 0px 15px;}
    .privacypage p {font-size: 13px;}
    .privacypage.RefundPolicypage h4 {font-size: 15px;}
    .privacypage.RefundPolicypage h4 {font-size: 15px;}
    .contactDetails div {font-size: 13px;margin: 0 5px;}
    .sidebarcommon {width: 100%;padding: 20px 20px 20px;}
    .sidebarcommon h2 {margin: 0px 0px 0px;font-size: 27px;}
    .sidebarcommon p {font-size: 16px;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }
}
@media only screen and (max-width: 600px) { 
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .redeempics {height: 340px;}
    .card-header-New {display: flex;flex-direction: column;row-gap: 11px;align-items: center;}
    .myaccountprofilepic {padding: 15px 15px;}
    .acctprofile {width: 90px;height: 90px;margin-bottom: 15px;}
    .myaccountprofilepic h4 {font-size: 17px;}
    .selectAccountssct {font-size: 18px;}
    .RedeemCreditModalMain {width: 95%;}
    .closebtn {top: 7px;right: 7px;width: 18px;height: 18px;}
    .RedeemCreditModalMain h1 {font-size: 24px;}
    .RedeemCreditModalMain {padding: 19px 19px;}
    .contactussubmit .formcomnbtn {width: 100%;padding: 8px 50px;}
    .bookspreviewModal .closePreviewModal {width: 18px;height: 18px;right: 19px;top: 19px;}
    .booksummrysetwrapper h4 {margin-bottom: 20px;font-size: 21px;}
    .booksummryset p {font-size: 14px;float: left;width: 100%;word-break: break-word;}
    .checkoutlistwrapper .checkoutlist {flex-direction: column;align-items: flex-start;}
    .footercopyright {font-size: 13px;}
    .Toastify__toast-container {width: 92% !important;top: 15px !important;left: 0 !important;right: 0 !important;margin: 0 auto !important;}
    .footermailjoin .footemialform .form-group {flex-direction: column; }
    .footermailjoin .footemialform .form-group input {margin: 0 0 10px;font-size: 15px !important;width: 100%;}
    .footermailjoin .danger-colorset {margin: 0px 0 10px;}
    .footermailjoin .footemialform .form-group .btn, .footermailjoin .footemialform .form-group .btn:focus {margin-top: 10px;width: 100%;}
    .myaccountwrpsheader .headertitle h4 {font-size: 28px;}
    .myaccountwrps {padding: 12px 12px;}
    .container.custom_container_new {width: 100%;}
    .commonheader {padding: 0px 10px;}
    .checkoulistwrps h2 {font-size: 24px;}
    .checkoutlistwrapper .checkoutlist h5 {font-size: 22px;}
    .checkoutlistwrapper .checkoutlist .previewImage img {width: 140px;height: 140px;margin: 0 0 15px 0px;}
    h1.sectionheaders {font-size: 30px;}
    .footer_section .col-md-5 .col-6 {width: 100%;}
    .posterlistwraps .poserrbooksrow .col-md-2.col-sm-4.col-xs-12{width: 100%;}
    .searchfilterbookswraps {flex-direction: column;}
    .searchfilterbookswraps select{margin: 10px 0 10px 0;}
    .searchfilterbookswraps h4{margin: 0px;}
    .responsiveSidebar {width: 100%;}
    .successpymentwrapper  {width: 90%;}
    .successpymentwrapper {width: 90%;padding: 50px 10px 0;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }

}
@media only screen and (max-width: 540px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .iframPayment {width: 90% !important;}
    .loginsignuptabs h1 {font-size: 28px;margin: 0 0 20px;}
    .loginPopupMain {width: 93%;font-size: 28px;margin: 0 auto 20px;}
    .responsiveSidebar .headerLinks ul li {margin: 0 0 15px;}
    .responsiveSidebar .headerLinks ul li a {text-align: center;}
    .bannerbtn, .bannerbtn:hover, .bannerbtn:focus {font-size: 22px;}
    .bannerssections img {width: 270px;}
    .bannerSectionWrp video {height: 360px;}
    .landingfixedpage video {height: 290px;}
    header.Header_section.Header_after_login .userLoginSignupwraps .userLoginSignupwrapsrow {align-items: center;}
    .privacypage.RefundPolicypage {padding: 0px;}
    .Explorebtn {padding: 15px 20px;font-size: 15px;}
    .welovworkthemsection {padding: 25px 25px;}
    .ourstudiosection { margin-bottom: 0px;}
    .ourstudiosoffiwraps .col-md-6 {padding: 0px;}
    .ourstudiodata h4 {font-size: 16px;margin: 0 0 13px;}
    .ourstudiodata h1 {font-size: 26px;}
    .meetfounderssection {margin-bottom: 0px;}
    .meetfoundercolswrps .meetfoundercols {margin-bottom: 35px;}
    .meetfounderssectionsww {padding: 30px 5px 30px;}
    .meetfounderssectionsww h4 {margin: 0 0 35px;}
    .meetfounderssection .col-md-12, .meetfounderssection .container {padding: 0px;}
    .whtweareaboutcols {text-align: center;padding: 25px 20px;}
    .aboutgamessection h1, .whtweareaboutsection h1, .meetfounderssectionsww h4 {font-size: 26px;}
    .sidebarcommon h2 svg {width: 20px;height: 20px;}
    .termscondtaceptsection .form-group-checkbox input:checked + label:after {top: 1px;}
    .prvidethiswhytoggleinfo {float: left;width: 100%;}
    .form-group-checkbox {font-size: 14px;}
    .prvidethiswhytoggleinfo div {font-size: 15px;}
    .prvidethiswhy img {width: 120px;height: 120px;}
    .paymentformsWraps.signupfields {width: 100%;padding: 17px 17px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow.userLoginSignupwrapsrowcenter .loginbtnbx {width: 100px;}
    .copyright {padding: 15px 0px 15px;font-size: 13px;}
    .contadetails img {width: 90px;}
    .contadetails h4 {font-size: 16px;}
    .contadetails {text-align: left;}
    .footprofilecol {width: 70%;}
    .footsocialcol {width: 30%;}
    .contactlinks h4 {font-size: 16px;}
    .contactlinks ul li {font-size: 14px;}
    .powerbysections p {width: 100%;font-size: 16px;}
    .powerbysections h3 {font-size: 15px;}
    .powerbysections.powerbysectionsfooter {padding: 25px 15px 25px;}
    .powerbysections {padding: 37px 0px;}
    .powerbysections.powerbysectionsfooter {padding: 25px 15px 25px;}
    .gamesonlinewrps {margin: 0;}
    .gamesonlinewrps .imageONvideo {position: relative;bottom: 0;left: 0;right: 0;margin: 0 auto;float: none;width: 150px;}
    .gamesonlinewrps iframe {height: 260px;}
    .signcmnbtn, .signcmnbtn:focus {padding: 14px 16px;font-size: 15px;margin: 20px 0 30px;}
    .powerbysections h5 {margin: 0 0 12px;}
    .powerbysections h1 {font-size: 30px;}
    .GamesGridwrapper .col-md-12 {padding: 0px;}
    .GamesGridwrapper {padding: 20px 0;}
    .gamelistwraps {border-radius: 24px;margin-bottom: 15px;}
    .gamelistwraps h4 {font-size: 18px;left: 20px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx a {font-size: 12px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx img {width: 68px;height: 60px;}
    .userLoginSignupwraps.userLoginSignupwrapswraps p {font-size: 12px;}
    .logogheader .logohere img {width: 90px;height: 90px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow {align-items: flex-start;}
    .userLoginSignupwraps {width: 310px;}
    .userLoginSignupwrapsrowRelative .loginbtnbx:first-child {left: auto;}
    .userLoginSignupwrapsrowRelative .loginbtnbx:last-child {right: auto;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx span {font-size: 11px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx h1 {font-size: 20px;}
    .Purchasebtn, .Purchasebtn:focus {font-size: 10px;}
    .commonheader h4 {margin: 55px 0px 0px;}
    .digitalposterssliders {padding: 60px 0px 60px;}
    .cancelmodal, .cancelmodal:focus {padding: 7px 25px;}
    .discardmodal, .discardmodal:focus {padding: 7px 25px;}
    .paymentssteps {column-gap: 0px !important;}
    .myaccountwrpsheader .poserrbooksrow .posterblogswrp {flex-direction: column;}
    .myaccountwrpsheader .poserrbooksrow .posterblogswrp .previewImage, .myaccountwrpsheader .poserrbooksrow .posterblogswrp img {width: 100%;height: auto;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }

}
@media only screen and (max-width: 500px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .statusPendingModel h1 {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
    }
    .statusPendingModel {
        width: 93%;    
        top: 5%;
    }
    .logogheader a span {font-size: 13px;}
    .logogheader h1 {float: left;font-size: 26px;}
    .logogheader {text-align: left;}
    .redeemcreditform{width: 100%;}
    .videoModalwrapper .videoplayer iframe {height: 275px;}
    .powerbysections h5 {font-size: 16px;}
    .powerbysections h1 {font-size: 25px;}
    .PurchesPostersction .purchesswraps .col-6 {padding: 0px;}
    .commonheader h1 {font-size: 28px;line-height: 41px;}
    .commonheader h4 {font-size: 36px;}
    .clickpaymnt, .clickpaymnt:focus {font-size: 15px;}
    .paymentforms .rowcustom:last-child {margin-bottom: 20px;}
    .rowcustom {margin-bottom: 10px;}
    .react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {width: 30.33% !important;}
    .dropdowncustome .amountdetailsdropdown {width: 96%;position: fixed;left: 0;right: 0;float: none;margin: 0 auto;top: 50px;}
    .digitalposterssliders {padding: 36px 0px 0px;}
    .rowcustom-col-2 {flex-direction: column;}
    .rowcustom.rowcustom-col-2 .form-group:first-child {margin-bottom: 10px;}
    .purchesBundleGroups {width: 331px;}
    .singlepostersection.PosterBundleswraper .purchesBundletypes .Purchasebtnbig {width: 100%;font-size: 11px;padding: 12px 4px;}
    .purchesswraps .col-md-6 {padding: 0px;}
    .succesiocn {width: 80px;height: 80px;top: -30px;}
    .successpymentbutns .btn {padding: 11px 12px;font-size: 13px;border-radius: 8px;}
    .successpymentbutns {padding: 18px 0px 18px;}
    .successpymentwrapper h4 {font-size: 20px;margin: 10px 0 10px;}
    .successpymentwrapper p {float: left;width: 100%;font-size: 13px;line-height: 24px; margin: 0px auto 10px;}
    .tarnsitionId {font-size: 12px;}
    .loginPopupMain {width: 90%;}
    .loginsignuptabs h1 {font-size: 38px;margin: 0 0 10px;}
    .loginsignuptabs h4 {font-size: 16px;margin: 0 0 15px;}
    .loginwitemilformwrps .form-group {margin: 0 0 15px;}
    .forgotpassword {margin: 0 0 20px;font-size: 14px;}
    .loginbtn, .loginbtn:hover, .loginbtn:focus {padding: 8px 19px;}
    .ordevidedstmrgin {margin: 10px 0 10px;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }

}
@media only screen and (max-width: 440px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .choose_card_method {column-gap: 9px;}
    .choose_card_method .selectPayment {width: 108px;height: 85px;font-size: 13px;}
    .selectPayment img {width: 29px ;height: 29px;}
    .bannerssections img {width: 210px;}
    .landingfixedpage video {height: 223px;}
    .chosseCustomNumber .formgroup {width: 100%;}
    .gamesonlinewrps iframe {height: 200px;}
    .sweepscoinbtn {font-size: 17px;}
    .searchBoxwithbtn{width: 100% !important;}
    .card-header-right{width: 100% !important;display: flex;}
    .card-header-right select{width: 50% !important;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }

}
@media only screen and (max-width: 400px) {
    .crypto-grid{grid-template-columns: repeat(2, 1fr);}
    .sidebarcommon p {font-size: 14px;}
    .togglebuttonrespo {top: 9px;}
    .logogheader h1 {font-size: 23px;}
    .logogheader a span {font-size: 12px;}
    .Header_section .col-md-12 {padding: 0px 5px;}
    .bannerbtn, .bannerbtn:hover, .bannerbtn:focus {font-size: 17px;white-space: pre-line;word-spacing: 42px;}
    .bannerssections img {width: 187px;}
    .videoModalwrapper .videoplayer iframe {height: 230px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx h1 {font-size: 16px;}
    .userLoginSignupwraps .userLoginSignupwrapsrow .loginbtnbx span {font-size: 10px;}
    .purchesBundleGroups {width: 100%;}
    .powerbysections h3 {font-size: 15px;}
    .option-box {width: 100%}
    .method-section h3{
        font-size: 20px!important;
    }
}
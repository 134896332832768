* {float: left;width: 100%;box-sizing: border-box;padding: 0;margin: 0;}
body {float: left;width: 100%;font-family: 'helveticaregular';box-sizing: border-box;position: relative;}
.wrapper {float: left;width: 100%;overflow: hidden;padding-top: 74px;}
.container {float: none;}
.container_header {max-width: 980px;}
.container-fluid.custom-container-fluid {padding: 0 20px;}
.container.pravcypolicycontainer {
    width: 680px;
}
.Verify_wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Verify_wrapper label{
    margin-bottom: 0px;
}
.Verify_wrapper span{
   color: blue; 
   font-size: 14px;
   font-weight: 700;
   float: right;
   width: 50px;
}
.Pending_acc{
    color: #5a7cd8 !important;
    background-color: rgba(56, 140, 236, 0.2) !important;
    float: left;
    width: auto;
    padding: 5px 7px;
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    border-radius: 4px;

}
.verify{
    display: flex;
    justify-content: end;
    align-items: end;
}
.verified{
    display: flex;
    justify-content: end;
    align-items: end;
}
.verified div{
    height: 20px;
    width: 20px;
}
.verify button{
    width: 110px;
    height: 24px;
    margin-bottom: 5px;
    border: 2px solid blue;
    background: #0013f7;
    color: white;
    font-weight: 700;
    padding: 0px;
    border-radius: 20px;
    position: absolute;
}
.verify button:hover{
    background-color: rgb(91, 91, 248);
    border: 2px solid rgb(91, 91, 248);
}
.updateCryptoBtn{
    font-family: sans-serif !important;
}
.payment-options {
    /* display: flex;
    justify-content: space-between; */
    gap: 20px;
    margin-bottom: 20px;
  }
  .payment-options h4{
    font-family: 'suez_oneregular';
    margin-bottom: 20px;

  }
  .Name_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;

  }
  .Name_wrapper p {
    margin: 0px;
    font-size: 13px;
    color: rgb(100, 100, 100);
  }
  
  .option-box {
    width: 60%;
  }
  .option-box #Withdroal_checkbox {
    width: 30% !important;
    height: 20px !important;
    /* width: 20px !important; */
  }
  .Name_wrapper.selected {
    border-color: #007bff; /* Highlight selected option */
    border: 2px solid #007bff;
    border-radius: 10px;
  }
  
  .option_wrapper{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 1rem;
  }
  .option {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px;
  }
  

  
  .option-img {
    width: 30px;
    height: 30px;
  }
  
  .option-name {
    font-size: 18px;
    font-weight: 500;
    margin-left: 20px;
  }
  
  .method-section {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  .method-section h3{
    font-family: 'suez_oneregular';
    font-size: 22px ;
    margin-bottom: 20px;
    

  }
  .Withdraw_Btn_Wrapper {
    display: flex;
    justify-content: right;
  }
/* header css start here */
.Header_section {
float: left;
width: 100%;
padding: 10px 0;
position: fixed;
background-color: rgba(255, 255, 255, 0.9);
backdrop-filter: blur(10px);
left: 0;
top: 0;
z-index: 999;
}
.main-header {
float: left;
width: 100%;
background-color: rgb(43,7,77);
padding: 40px 20px;
font-family: 'suez_oneregular';
color: #fff;
}
.comnpages {
float: left;
width: 100%;  
padding: 30px 0;
}
.comnpages p {
font-size: 17px;
}
.dropdousericon {
height: 26px !important;
margin: 0px 0px -3px 18px !important;
width: 26px !important;
}
.logogheader .loginWraps:hover {color: #DBAD47;}
.logogheader .loginWraps:hover svg {fill: #DBAD47;}
.loginwrapsbtn svg {
float: left;
height: 25px;
width: 25px;
}
.main-header h1 {
margin: 0px;
}
.logogheader {
float: left;
width: 100%;
display: flex;
position: relative;
text-align: center;
align-items: center;
justify-content: center;
}
.logogheader a{
    text-decoration: none;
}
.logogheader h1 {
float: none;
width: auto;
display: table;
margin: 0 auto;
font-size: 45px;
color: rgb(39,36,67);
font-family: 'suez_oneregular';
}
.logogheader a span {
color: #333;
line-height: normal;
font-size: 15px;
}
.userdropdownset {
float: right;
width: auto;
display: flex;
align-items: center;
position: absolute;
right: 0;
}
.logogheader .loginWraps {
width: auto;
float: right;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
font-size: 19px;
}
.userdropdownset .signupbtnnew {
border: 1px solid #333;
padding: 5px 10px;
box-sizing: border-box;
border-radius: 4px;
margin-left: 15px;
}
.userdropdownset .signupbtnnew:hover {
border-color: #DBAD47;
} 
.userdropdownset.beforeloginuserDrop .loginWraps {
font-size: 17px;
}
.userdropdownset.beforeloginuserDrop .loginWraps svg {
width: 21px;
height: 21px;
margin-right: 9px;
}
.container_header_Nav {
max-width: 100%;
}
.logogheader .loginWraps svg {
width: 26px;
height: 26px;
margin-right: 13px;
fill: rgb(218,57,43);
}
.Toastify__close-button--light {
width: auto;
}
.logogheader .loginWraps img {
width: 26px;
height: 26px;
border-radius: 100%;
margin-right: 13px;
object-fit: cover;
object-position: center;
}
/* header css end here */

/* banner section start here */
.bannerSectionWrp {
float: left;
width: 100%;
position: relative;
}
.bannerSectionWrp video {
float: left;
width: 100%;
height: 544px;
object-fit: cover;
object-position: center;
background-color: #000;
}
.bannervideocontent {
position: absolute;
left: 0;
top: 0;
padding: 40px 0;
margin: 0 auto;
right: 0;
}
.bannerssections {
float: left;
width: 100%;
display: flex;
justify-content: center;
color: #fff;
align-items: center;
}
.bannerssections h4 {
font-family: 'suez_oneregular';
font-size: 55px;
text-align: center;
line-height: 73px;
margin: 0px;
}
.bannerbtn, .bannerbtn:hover, .bannerbtn:focus {
background-color: #DBAD47;
border-color: #DBAD47;
font-size: 26px;
border-radius: 0px 0px 10px 10px;
font-family: 'suez_oneregular';
padding: 10px 10px;
box-shadow: none !important;
text-align: center;
color: #fff;
text-decoration: none;
}
.bannerssections img {
width: 437px;
margin-bottom: 15px;
}
.powerbysections {
float: left;
width: 100%;
text-align: center;
padding: 37px 0px 95px;
position: relative;
}
.powerbysections h1 {
float: left;
width: 100%;
font-size: 50px;
color: rgb(39,36,67);
font-family: 'suez_oneregular';
line-height: normal;
}
.powerbysections p {
float: none;
width: 78%;
color: rgb(39,36,67);
font-size: 18px;
margin: 0 auto;
display: table;
}
/* banner section end here */

/* footer section start here */
.footer_section {
float: left;
width: 100%;
position: relative;
}
.footwrapper {
float: left;
width: 100%;
padding: 50px 25px 90px;
border-top: 1px solid rgb(39,36,67);
border-bottom: 1px solid rgb(39,36,67);
}
.entrylink {
color: #DBAD47 !important;
}
.contactlinks {
float: left;
width: 100%;
position: relative;
}
.contactlinks h4 {
float: left;
width: 100%;
font-family: 'helveticabold';
font-size: 21px;
margin: 0 0 25px;
color: rgb(39,36,67);
}
.contactlinks ul {
padding: 0;
margin: 0;
float: left;
width: 100%;
}
.contactlinks ul li {
float: left;
width: 100%;
list-style: none;
display: flex;
color: #757575;
margin-bottom: 10px;
font-size: 16px;
}
.contactlinks ul li a {
color: #757575;
text-decoration: none;
}
.contadetails {
float: left;
width: 100%;
position: relative;
text-align: center;
}
.contadetails h4 {
float: left;
width: 100%;
font-family: 'helveticabold';
font-size: 21px;
margin: 0 0 25px;
}
.contadetails img {
width: 144px;
float: none;
display: table;
margin: 0 auto;
}
.copyright {
float: left;
width: 100%;
text-align: center;
padding: 65px 0px 55px;
color: rgb(117,117,117);
}
/* footer section end here */

/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;border-radius: 7px;position: relative;display: flex;}
.selectNoInputField input {height: 48px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 16px;}
.selectNoInputField .inputBoxSelect::after{content: "";float: left;width: 1px;height: 25px;background-color: #c1c1c1;position: absolute;right: 0;top: 9px;}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;}
.flagsDropdown {
float: left;
width: 220px;
margin: 0px;
padding: 0px;
position: absolute;
z-index: 9;
background-color: #fff;
box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
left: 0;
bottom: 51px;
top: auto;
display: none;
}
.flagsDropdown.active {
display: block;
}
.passwordboxset input {padding: 7px 35px 7px 12px !important;}
.usernameheader {
text-overflow: ellipsis;
overflow: hidden;
max-width: 90px;
display: inline-block;
width: 90px;
white-space: nowrap;
}
.logoutbtn {
background-color: #DBAD47;
border: 0px;
display: flex;
align-items: center;
float: right;
width: auto;
position: absolute;
left: 0;
padding: 8px 12px;
color: #fff;
text-transform: uppercase;
line-height: normal;
font-size: 15px;
border-radius: 5px;
box-shadow: 0px 0px 14px -4px #adadad;
}
.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
    display: flex;
}
.logoutbtn img {
width: 23px;
filter: invert(100%) sepia(11%) saturate(7500%) hue-rotate(267deg) brightness(115%) contrast(118%);
margin: 0 6px 0 0;
}
.passwordboxset .input-group-text {
width: auto;
background: transparent;
border: 0px;
position: absolute;
right: 0;
top: 3px;
padding: 5px 10px;
height: auto;
cursor: pointer;
}
.passwordboxset .input-group-text svg {
width: 18px;
}
.flagsDropdown input {
border: 0 !important;
font-size: 13px !important;
height: 48px;
outline: none;
padding: 10px;
width: 100%;
}
.flagsDropdown ul {
float: left;
width: 100%;
padding: 0px;
margin: 0px;
max-height: 200px;
overflow: auto;
}
.flagsDropdown li {
float: left;
width: 100%;
list-style: none;
display: flex;
padding: 10px 13px;
font-size: 12px;
cursor: pointer;
text-align: left;
vertical-align: middle;
align-items: center;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
}
.flagsDropdown li:hover {
background-color: #eee;
}
.flagsDropdown li img {
width: 26px;
margin: 0px 8px 0px 0px;
}
.sidebarcommon .flagsDropdown ul {
max-height: 153px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
padding: 1px;
border: 0px;
height: 100%;
width: 100%;
display: flex;
position: relative;
align-items: center;
text-align: center;
justify-content: flex-start;
background-color: transparent;
margin-right: 5px;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 20px;
height: 20px;
border-radius: 100%;
margin: 0px 7px 0px 0px;
object-fit: cover;
}
.noteemail {
color: #9c9c9c;
font-size: 14px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
width: 7px;
margin: 0px 0px 0px 3px;
}
.form-group textarea {height: 120px;border: 1px solid #E0E0E5;border-radius: 7px;font-size: 15px;box-shadow: none !important;padding: 8px 17px;resize: none;}

.btn.formcomnbtn {
    background-color: rgb(218,57,43);
    border-color: rgb(218,57,43);
    color: #fff;
    width: auto;
    margin: 0 auto;
    float: none;
    padding: 6px 37px;
    font-size: 15px;
    border-radius: 10px;
    box-shadow: none !important;
}
/* country code end here */


/* signup sidebar css start here */
.backgroundmodal {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
}
.formcomnbackbtn {
background-color: transparent !important;
border-color: transparent !important;
color: #333 !important;
width: auto;
margin: 0 auto;
float: none;
padding: 6px 37px;
font-size: 15px;
border-radius: 10px;
box-shadow: none !important;
}
.sidebarcommon {
float: left;
width: 600px;
position: fixed;
z-index: 999;
right: 0;
top: 0;
background-color: rgb(219, 173, 71);
padding: 0px 50px 20px;
overflow: auto;
height: 100%;
transform: translateX(100%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
.sidebarcommon.open {
transform: translateX(0%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-ms-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
.sidebarcommon h2{
float: left;
width: 100%;
font-family: 'suez_oneregular';
font-size: 34px;
margin: 0px 0px 8px;
color: #fff;
}
.sidebarcommon h2 svg {
width: 34px;
height: 34px;
float: right;
fill: #fff;
cursor: pointer;
}
.sidebarcommon p {
float: left;
width: 100%;
font-size: 18px;
color: #fff;
margin: 0 0 4px 0px;
}
.sidebarcommon p .loginlink {
width: auto;
margin: 0 10px 0px 0px;
text-decoration: underline;
cursor: pointer;
}
.erroBlockSet {
float: left;
width: 100%;
padding: 11px 10px;
border: 2px solid red;
border-radius: 10px;
font-size: 14px;
color: red;
background-color: #ffeeee;
margin: 0 0 10px;
text-align: center;
}
.paswordfield label {
display: flex;
justify-content: space-between;
align-items: center;
}
.paswordfield label img {
width: 13px;
height: 13px;
cursor: pointer;
object-fit: contain;
object-position: center;
} 
.passwordnotewrapper {
float: right;
width: auto;
position: relative;
}
.passwordnotewrapper2 {
    float: right;
    width: 20px;
    position: relative;
    }
.passwordnotewrapper ol {
float: left;
width: 200px;
background-color: #fff;
position: absolute;
right: 0;
z-index: 999;
bottom: 18px;
padding: 10px 10px 10px 25px;
margin: 0px;
box-shadow: 0px 0px 27px -6px #ccc;
border-radius: 9px;
display: none;
}
.passwordnotewrapper2 ol {
/* float: left; */
width: 170px;
background-color: #fff;
position: absolute;
right: 0;
z-index: 999;
bottom: 18px;
margin-left:-30px ;
padding: 10px 10px 0px 10px;
margin: 0px;
box-shadow: 0px 0px 27px -6px #ccc;
border-radius: 9px;
display: none;
/* display: block;
color: black;
font-size: 14px; */


}
.passwordnotewrapper2 img {
    margin-bottom: 15px;
    margin-left: 10px;
    cursor: pointer;
}
.passwordnotewrapper:hover ol {
    display: block;
}
.passwordnotewrapper2:hover ol {
    display: block;
    color: black;
    font-size: 14px;
}
.termscondtaceptsection {
float: left;
width: 100%;
margin: 0 0 5px;
}
.termscondtaceptsection .form-group-checkbox label:before {
border: 1px solid #333;
border-radius: 100%;
height: 18px;
width: 18px;
padding: 0px;
margin-right: 14px;
}
.termscondtaceptsection .form-group-checkbox {
display: inline-flex;
align-items: center;
float: left;
width: auto;
font-size: 14px
}
.passwordFieldwrap {
    float: left;
    width: 100%;
    position: relative;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}
.passwordFieldwrap input {
    border: 1px solid #C7C7C7 !important;
}
.passwordFieldwrap .input-group-text {
    top: 0 !important;
    height: 100% !important;
}
.termscondtaceptsection .form-group-checkbox #termsconditions {
height: 19px;
width: 19px;
margin: 0px 7px 0px 0px;
}
.termscondtaceptsection .form-group-checkbox #emailcheck, .termscondtaceptsection .form-group-checkbox #StoreSameAs {
    height: 19px;
    width: 19px;
    margin: 0px 7px 0px 0px;
}
.termscondtaceptsection .form-group-checkbox #StoreSameAs:disabled ~ label{
    opacity: 0.5;
}
.loginsignuptabsresetpssword .passwordFieldwrap input {
    height: 40px !important;
    padding: 5px 10px !important;
}
.loginsignuptabsresetpssword .loginPopupMain .form-group label {
    margin: 0 0 8px !important;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    cursor: no-drop !important;
    background: color #f84646 !important;;
    background-color: #f84646;
    border-color: #f84646 !important;

}

.termscondtaceptsection .form-group-checkbox label a {
width: auto;
float: none;
text-decoration: underline;
color: #333;
}
.termscondtaceptsection .form-group-checkbox input:checked + label:after {
top: 2px;
left: 0;
background-image: url("../img/check.png");
background-repeat: no-repeat;
background-size: 16px;
height: 18px;
width: 18px;
transform: none;
background-position: center;
border: 0;
margin: 0;
}
.passwordnotewrapper ol li {
font-size: 11px;
}
.prvidethiswhytoggleinfo p a {
width: auto;
float: none;
color: #fff;
display: inline-block;
}
.rowcustom {
float: left;
margin-bottom: 20px;
width: 100%;
}
.rowcustom-col-2 {
display: flex;
justify-content: space-between;
}
.rowcustom-col-3 {
display: flex;
flex-wrap: wrap;
justify-content: space-between;
}
.rowcustom.rowcustom-col-2 .form-group {
flex-basis: 49%;
}
.rowcustom.rowcustom-col-3 .form-group {
flex-basis: 32%;
}
.form-group, .form-group label {
float: left;
position: relative;
width: 100%;
font-size: 14px;
margin: 0 0 3px;
}
.form-groupfiled {
float: left;
position: relative;
width: 100%;
}
.form-group input {
border: 1px solid #e0e0e5;
border-radius: 7px;
box-shadow: none!important;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
font-size: 13px;
height: 44px;
padding: 8px 14px;
}
.paymentformsWraps.signupfields {
float: left;
width: 100%;
padding: 30px 30px;
background-color: #fff;
border-radius: 25px;
box-shadow: 0px 1px 4px 0px rgb(0 0 0 / 60%);
position: relative;
}
.loaderfilewrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 29px;
    overflow: hidden;
    font-size: 16px;
}
.loaderfilewrapper .spinner-border {
    margin: 0 0 10px;
}
.paymentformsWraps.signupfields .form-group label {
font-size: 14px;
margin: 0 0 5px;
letter-spacing: 0.2px;
}
.paymentformsWraps.signupfields .form-group input {
padding: 7px 12px;
height: 40px;
font-size: 12px;
}
.paymentformsWraps.signupfields .form-group select {
padding: 7px 12px;
height: 40px;
font-size: 12px;
border: 1px solid #e0e0e5;
border-radius: 7px;
box-shadow: none!important;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
background-image: url("../img/careticons.png");
background-repeat: no-repeat;
background-position: 95% center;
background-size: 13px;
}
.paymentformsWraps.signupfields .selectNoInputField .inputBoxSelect {
padding-left: 10px;
width: 70px;
}
.paymentformsWraps.signupfields .form-group .react-datepicker-popper .react-datepicker__header__dropdown select {
    background-image: none;
}
.paymentformsWraps.signupfields .selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 17px;
height: 17px;
margin: 0px 6px 0px 0px;
}
.paymentformsWraps.signupfields .selectNoInputField .inputBoxSelect .selectEmojis .countselet {
display: none;
}
.paymentformsWraps.signupfields .selectNoInputField .inputBoxs {
border: 0px;
}
.paymentformsWraps.signupfields .selectNoInputField .inputBoxSelect::after {
height: 15px;
}
.paymentformsWraps.signupfields .form-groupfiled .errorsuccessicon {
right: 11px;
top: 14px;
width: 14px;
}
.paymentformsWraps.signupfields .rowcustom.rowcustom-col-2 .form-group {
flex-basis: 48%;
}
.form-groupfiled .errorsuccessicon {
float: left;
position: absolute;
right: 9px;
top: 15px;
width: 17px;
}
.prvidethiswhy {
float: left;
width: 100%;
padding: 20px 0;
}
.prvidethiswhy img {
width: 165px;
height: 165px;
object-fit: cover;
margin: 0 auto 20px;
float: none;
display: table;
}
.prvidethiswhytoggleinfo {
float: none;
width: 60%;
margin: 0 auto;
display: table;
text-align: center;
}
.prvidethiswhytoggleinfo div {
color: #fff;
font-size: 18px;
text-decoration: underline;
cursor: pointer;
}
.prvidethiswhytoggleinfo p {
font-size: 12px;
}
.danger-color {
color: #f84646;
float: left;
font-size: 12px;
margin: 4px 0 0;
width: 100%;
}
.form-group.error .form-groupfiled input, .form-group.error .form-groupfiled select, .form-group.error .form-groupfiled textarea {
border-color: #F84646;
}
.form-group.error .selectNoInputField {
border-color: #F84646;
}
/* signup sidebar css end here */

/* login sign up page css start here */
.closeModal {width: auto;padding: 0px;border: 0px;background: transparent;position: absolute;right: 20px;top: 21px;}
.loginsignupModals {
float: left;
width: 100%;
height: 100vh;
z-index: 9999;
display: flex;
align-items: center;
justify-content: center;
background-color: #F6F4F0;
position: fixed;
left: 0;
top: 0;
}
.loginsignuptabsresetpssword {
height: calc(100vh - 140px);
display: flex;
align-items: center;
flex-direction: column;
justify-content: center;  
}
.loginPopupMain {
float: none;
width: 320px;
margin: 0 auto;
display: table;
text-align: center;
}
.loginsignuptabs h1 {
font-size: 48px;
margin: 0 0 15px;
font-family: 'suez_oneregular';
color: rgb(39,36,67);
}
.loginsignuptabs h4 {
font-size: 17px;
display: flex;
color: rgb(39,36,67);
justify-content: center;
align-items: center;
margin: 0 0 37px;
}
.loginsignuptabs h4 span {
cursor: pointer;
color: rgb(218,57,43);
width: auto;
margin: 0 0 0 5px;
}
.fbgoglrbnts {
float: left;
width: 100%;
position: relative;
}
.fbgoglrbnts .btn , .fbgoglrbnts .btn:focus {
display: flex;
justify-content: center;
align-items: center;
border-radius: 0px;
margin: 0 0 17px;
font-size: 15px;
position: relative;
padding: 8px 14px;
box-shadow: none !important;
}
.fbgoglrbnts .btn .iconsn {
float: left;
width: 20px;
height: 20px;
position: absolute;
left: 14px;
display: flex;
justify-content: center;
align-items: center;
}
.fbgoglrbnts .btn.google .iconsn {
background-color: #fff;
}
.fbgoglrbnts .btn.google .iconsn img {    
width: 13px;
}
.fbgoglrbnts .btn.facebook {
background-color: #3b5998;
}
.fbgoglrbnts .btn.google {
background-color: #4285f4;
}
.ordevidedst {
float: left;
width: 100%;
position: relative;
display: flex;
justify-content: center;
align-items: center;
margin: 0 0 15px;
}
.ordevidedst::after {
content: "";
float: left;
width: 100%;
height: 1px;
background: rgba(199,199,199);
position: absolute;
left: 0;
top: 13px;
}
.ordevidedst span {
width: auto;
background-color: #F6F4F0;
float: left;
z-index: 99;
padding: 0px 13px;
}
.loginwithemail, .loginwithemail:focus {
border-radius: 0px;
margin: 0 0 17px;
font-size: 16px;
position: relative;
padding: 7px 14px;
background: transparent !important;
color: #000 !important;
border-color: rgba(199,199,199);
box-shadow: none !important;
}
.loginwithemail:hover {
border-color: #000 !important;
}
.loginwitemilformwrps {
float: left;
width: 100%;
text-align: left;
}
.loginwitemilformwrps .form-group {
float: left;
width: 100%;
margin: 0 0 30px;
}
.loginwitemilformwrps .form-group label {
color: rgb(117,117,117);
font-size: 16px;
margin: 0px !important;
line-height: normal;
}
.loginwitemilformwrps .form-group .form-control {
border-bottom: 1px solid #C7C7C7 !important;
border-radius: 0px;
height: 34px;
border: 0px;
padding: 5px 0px;
background: transparent;
font-size: 15px;
}
.loginwitemilformwrps .form-group .form-control:hover {
border-color: rgb(0,0,0) !important;    
}
.loginwitemilformwrps .form-group .form-control:focus {
border-color: rgb(218,57,43) !important;
}
.forgotpassword {
float: left;
width: auto;
text-decoration: underline;
font-size: 16px;
margin: 0 0 20px;
cursor: pointer;
}
.loginbtn , .loginbtn:hover, .loginbtn:focus {
border-radius: 0px;
padding: 11px 20px;
background-color: rgb(218,57,43);
border-color: rgb(218,57,43);
box-shadow: none !important;
display: flex;
align-items: center;
justify-content: center;
}
.ordevidedstmrgin {
margin-top: 30px;
}
.socillinks {
float: left;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
margin: 10px 0 45px;
}
.socillinks div {
width: auto;
margin: 0 15px;
}
.socillinks div .FaceBookIcon {
filter: invert(68%) sepia(33%) saturate(1269%) hue-rotate(182deg) brightness(99%) contrast(84%);
}
.form-groupfiled .react-datepicker__tab-loop .react-datepicker-popper {
width: 245px !important;
inset: 0 0 auto auto !important;
}
.form-groupfiled .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker__day-names {
display: flex;
justify-content: center;
align-items: center;
}
.form-groupfiled .react-datepicker__tab-loop .react-datepicker-popper .react-datepicker .react-datepicker__triangle {
transform: translate(226px, 0px) !important;
}
.form-groupfiled label.uploadfileinput {
border: 1px solid #e0e0e5;
border-radius: 7px;
box-shadow: none!important;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
height: 40px;
padding: 7px 12px;
display: flex;
justify-content: center;
align-items: center;
font-size: 14px !important;
}
.form-groupfiled label.uploadfileinput input.form-control {
float: left;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
opacity: 0;
color: #7a7a7a;
}
.form-groupfiled label.uploadfileinput svg {
width: 15px;
height: 15px;
margin: 0px 0px 1px 5px;
}
.form-groupfiled label.uploadfileinput svg g {
fill: #7a7a7a;
}
.form-groupfiled label.uploadfileinput svg g rect:first-child {
width: 3px;
}
.form-groupfiled label.uploadfileinput:hover {
background-color: #e0e0e5;
color: #000;
}
.uplodfiles {
float: left;
width: 100%;
font-size: 13px;
display: flex;
justify-content: center;
align-items: center;
color: #8b8b8b;
}
.uplodfiles img {
width: 17px;
margin: 0 7px 0px 0px;
cursor: pointer;
}
.uplodfiles {
float: left;
width: 100%;
font-size: 13px;
color: #8b8b8b;
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
margin: 1px 0 0;
}
.uplodfiles img {
width: 17px;
margin: 0 7px 0px 0px;
cursor: pointer;
}
.uplodfiles span {
width: auto;
}
/* login sign up page css end here */

.CommonDropdownWraps {
    float: left;
    width: 190px;
    background-color: #fff;
    position: absolute;
    top: 42px;
    bottom: auto;
    z-index: 9;
    padding: 10px 10px;
    left: 0;
    border: 1px solid #D7D7D7;
    text-align: left;
}
.CommonDropdownWraps ul {padding: 0px;margin: 0px;float: left;width: 100%;}
.CommonDropdownWraps ul li {float: left;width: 100%;list-style: none;position: relative;}
.CommonDropdownWraps ul li a {text-decoration: none;color: #000;padding: 10px 10px;font-size: 15px;cursor: pointer;}
.CommonDropdownWraps ul li a:hover {color: rgb(117, 117, 117);}
.CommonDropdownWraps ul li:last-child::before {content: "";float: left;width: 100%;height: 1px;background-color: #D7D7D7;position: absolute;top: 0;left: 0;}
.loaderFile {
    border: 2px solid #7e7e7e;
    border-radius: 50%;
    border-top: 2px solid #ffffff;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
}
.uplodfiles .loaderFile {
    margin-left: 5px;
}
/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* account section details */
.accoutntwrapper {float: left;width: 100%;position: relative;padding: 40px 0;}
.myacountsatabswrps {float: left;width: 100%;position: relative;}
.myacountsatabswrps .row {justify-content: flex-start;}
.myacountsatabswrps .row .col-md-3 {width: 29%;}
.myacountsatabswrps .row .col-md-9 {width: 68%;padding: 0px;}
.redeemprizewrapper img {
    cursor: pointer;
    height: 145px;
    width: 100%;
    margin: 0 auto;
    display: table;
    float: none;
    object-fit: cover;
    object-position: center;
}
.redeemprice_wrapper h4 {font-size: 18px;margin: 0 0 20px;}
.myaccountprofilepic {float: left;width: 100%;border: 1px solid rgb(43,7,77);text-align: center;padding: 25px 20px;position: relative;margin: 0 0 25px;    background-color: rgb(43,7,77);color: #fff;}
.myaccountprofilepic h4 {float: left;width: 100%;font-size: 16px;}
.accountsRouting {float: left;width: 100%;border: 1px solid #333;padding: 14px 32px;position: relative;}
.accountsRouting a {float: left;width: 100%;font-size: 17px;text-decoration: none;color: #333;margin: 0 0 15px;line-height: normal;cursor: pointer;}
.accountsRouting a:hover, .accountsRouting a.active {color: rgb(218,57,43);}
.imageloader {
float: left;
width: 100%;
height: 100%;
position: absolute;
background-color: rgba(255,255,255,0.7);
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
}
.accountsRouting a:last-child {margin-bottom: 0px;}
.acctprofile {float: none;width: 90px;height: 90px;margin: 0 auto 11px;cursor: pointer;position: relative;overflow: hidden;border-radius: 100%;display: block;}
.acctprofile img {float: left;width: 100%;height: 100%;object-fit: cover;object-position: center;}
.acctprofile .uploadfileovarlay {float: left;width: 100%;height: 100%;background: rgba(0,0,0,0.4);position: absolute;left: 0;top: 0;cursor: pointer;justify-content: center;flex-direction: column;align-items: center;display: none;}
.acctprofile:hover .uploadfileovarlay {display: flex;}
.acctprofile .uploadfileovarlay svg {width: 29px;height: 29px;border-radius: 100%;padding: 2px;}
.acctprofile .uploadfileovarlay input[type="file"] {opacity: 0;position: absolute;}
.acctprofile .uploadfileovarlay .svgwrp {width: 29px;height: 29px;background: rgb(43,7,77, 0.9);border-radius: 100%;display: flex;justify-content: center;align-items: center;position: relative;cursor: pointer;z-index: 99;}
.container.custom_container_new {max-width: 980px;display: table;}
.myaccountwrps {float: left;width: 100%;padding: 20px 0px 20px 10px;position: relative;margin: 0 0 25px;}
.myaccountwrpsheader {float: left;width: 100%;border-bottom: 1px solid rgba(18, 18, 18, 0.2);padding-bottom: 5px;margin-bottom: 25px;}
.myaccountwrpsheader .headertitle {float: left;width: auto;display: inline-grid;}
.myaccountwrpsheader .headertitle h4 {font-size: 23px;margin: 0 0 10px;font-family: 'reklame_scriptmedium';color: rgb(39, 36, 67);}
.myaccountwrpsheader .headertitle p {float: left;width: 100%;margin-bottom: 20px;font-size: 13px;color: rgb(39, 36, 67);}
.myaccountwrpsheader .bttngroup {float: right;width: auto;display: inline-flex;}
.discardbtn {color: #DA392B !important;font-family: 'reklame_script_demoregular';background-color: transparent !important;border-color: #DA392B;border-width: 1px;width: auto;font-size: 15px;padding: 10px 26px;border-radius: 0px;margin-right: 11px;box-shadow: none !important;line-height: normal;}
.discardbtn:hover {color: #DA392B;border-color: #DA392B;}
.updteinfobtn {color: #fff;font-family: 'reklame_script_demoregular';background-color: #DA392B;border-color: #DA392B;border-width: 1px;width: auto;font-size: 15px;padding: 10px 26px;border-radius: 0px;box-shadow: none !important;line-height: normal;}
.rowcustomright {
    float: left;
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
.selectNoInputField .inputBoxs {border: 0px;}
.updteinfobtn:hover  {background-color: rgba(218, 57, 43, 0.7);color: rgba(246, 244, 240, 0.7);border-color: #DA392B;}
.accountwrps {float: left;width: 100%;}
.accountwrps h4 {
    float: left;
    width: 100%;
    font-size: 19px;
    margin: 0 0 10px;
    color: rgb(39, 36, 67);
}
.accountwrps h5 {
    float: left;
    width: 100%;
    font-size: 14px;
    color: rgb(39, 36, 67);
    margin: 0 0 27px;
}
.ModalBackground {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(85,85,85,.75);
    z-index: 999;
}
.paymentformsWraps {float: left;width: 100%;}
.commonpopup {float: left;width: 582px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 9999;padding: 80px 32px;background-color: #fff;}
.commonpopup .closepopup {background: transparent;border: 0px;box-shadow: none;position: absolute;right: 13px;top: 20px;width: 32px;cursor: pointer;}
.commonpopup .commonpopupdetails {float: left;width: 100%;text-align: center;}
.commonpopup .commonpopupdetails h1 {font: normal normal normal 31px/1.25em avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;font-family: 'reklame_scriptmedium';margin: 0 0 29px;}
.commonpopup .commonpopupdetails p {font: normal normal normal 16px/2em avenir-lt-w01_85-heavy1475544,avenir-lt-w05_85-heavy,sans-serif;width: 87%;float: none;margin: 0 auto 41px;display: table;}
.cancelmodal, .cancelmodal:focus {font-size: 14px;width: auto;border: 1px solid #000000;border-radius: 0px;background: transparent;color: #000000;padding: 7px 40px;float: none;margin: 0 5px;box-shadow: none !important;outline: none !important;}
.cancelmodal:hover {color: rgba(0, 0, 0, 0.7);background: transparent;border-color: rgba(0, 0, 0, 0.7);}
.discardmodal, .discardmodal:focus {font-size: 14px;width: auto;border: 1px solid #000000;border-radius: 0px;background: #000000;color: #fff;padding: 7px 40px;float: none;margin: 0 5px;box-shadow: none !important;outline: none !important;}
.discardmodal:hover {background-color: rgba(0, 0, 0, 0.7);border-color: rgba(0, 0, 0, 0.7);color: rgba(255, 255, 255, 0.7);}
.commonpopupbuttons {float: left;width: 100%;text-align: center;}
.redeembackground {
float: left;
width: 100%;
height: 100%;
background-color: rgba(85,14,155, 0.56);
position: fixed;
z-index: 999;
left: 0;
top: 0;
}
.redeemprice_wrapper {
float: left;
width: 100%;
background-color: rgb(43,7,77);
color: #fff;
text-align: center;
padding: 20px;
}
.redeemprizewrapper {
float: left;
width: 100%;
display: flex;
justify-content: center;
}
iframe {
left: 0;
}
.redeemprizewrapper .col-3 {
padding: 0 7px;
margin-bottom: 14px;
}
/* account section details */

/* redeem credit modal details */
.RedeemCreditModalMain {
float: left;
width: 862px;
position: fixed;
background-color: rgb(43,7,77);
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
padding: 35px 35px;
z-index: 9999;
}
.RedeemCreditModalMain h1 {
color: #fff;
font-family: 'suez_oneregular';
text-align: center;
font-size: 32px;
margin: 0 0 40px;
}
/* .redeempics {
float: left;
width: 100%;
text-align: center;
display: flex;
flex-direction: column;
}
.redeempics img {
width: 225px;
height: 225px;
object-fit: cover;
margin: 0 auto;
} */
.redeempics {
float: left;
width: 100%;
text-align: center;
display: flex;
flex-direction: row;
column-gap: 20px;
}
.redeempics img {
width: 290px;
height: 290px;
object-fit: cover;
margin: 0 auto;
}
/* .redeemcreditform {
width: 310px;
margin: 20px auto 0;
} */
.redeemcreditform {
width: 87%;
margin: 20px auto 0;
}
.reedem_wrapper h4 {color: #fff;font-size: 17px;margin: 0 0 10px;}
.redeemcreditform input {
border: 2px solid rgb(218,57,43);
border-radius: 8px;
text-align: center;
font-size: 16px;
height: 42px;
margin-bottom: 10px;
box-shadow: none !important;
}
.redeemcreditform .danger-colorset {
    margin: 0px;
}
.redeemcreditform input::placeholder{
color: #000;
}
.redeemcreditform .btn, .redeemcreditform .btn:focus {
background-color: rgb(218,57,43);
border-color: rgb(218,57,43);
color: #fff;
width: auto;
margin: 0 auto;
float: none;
padding: 6px 37px;
font-size: 15px;
border-radius: 10px;
}
.redeemcreditform .btn {
margin-top: 10px !important;
}
.redeemcreditform .btn:hover {
background-color: #fff;
color: #000;
border-color: #333;
}
.closebtn {
float: right;
width: 30px;
height: 30px;
position: absolute;
right: -39px;
top: 1px;
cursor: pointer;
}
.closebtn svg {
fill: #fff;
}
/* redeem credit modal details */

/* contact us css start here */
.privacypage.RefundPolicypage {
    float: left;
    width: 100%;
}
.contactusform {
float: left;
width: 100%;
padding: 40px 0;
position: relative;
}
.privacypage.RefundPolicypage h4 {text-align: center;margin-bottom: 14px;font-size: 25px;font-family: avenir-lt-w01_35-light1475496,avenir-lt-w05_35-light,sans-serif;color: #333;font-weight: 600;}
.contactDetails {float: left;width: 100%;display: flex;align-items: center;justify-content: center;margin: 0 0 55px;position: relative;}
.contactDetails div {width: auto;margin: 0 15px;font-size: 17px;}
.contactDetails div a {color: #333;text-decoration: none;float: none;width: auto;}
.privacypage.RefundPolicypage .paymentformsWraps .form-group input {font-size: 15px;}
/* contact us css end here */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.loginwitemilformwrps .form-group .input-group-text {
background-color: initial;
border: 0;
cursor: pointer;
position: absolute;
right: 0;
top: 18px;
width: auto;
}
.danger-colorset {
color: #f84646;
float: left;
font-size: 12px;
margin: 4px 0 0;
width: 100%;
}
.loginwitemilformwrps .form-group .input-group-text svg {
height: 20px;
width: 20px;
}
.loginsignuptabs {
float: none;
width: 100%;
text-align: center;
}
.loginPopupMain {
float: none;
width: 320px;
margin: 0 auto;
display: table;
text-align: center;
}
.loginsignuptabs h1 {
font-size: 48px;
margin: 0 0 15px;
}
.loginwrapsbtn img {
height: 25px;
width: 25px;
object-fit: cover;
object-position: center;
border-radius: 100%;
}
.loginsignuptabs h4 {
font-size: 17px;
display: flex;
color: #000;
justify-content: center;
align-items: center;
margin: 0 0 37px;
}
.loginsignuptabs h4 span {
cursor: pointer;
color: #0A75B2;
width: auto;
margin: 0 0 0 5px;
}

/* responsive sidebar css start here */
.responsiveSidebar {
float: left;
width: 400px;
position: fixed;
right: 0;
top: 0;
background: rgba(255, 255, 255, 255);
z-index: 9999;
height: 100%;
padding: 75px 30px;
transform: translateX(100%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
.responsiveSidebar .headeraccounts {
width: 100%;
padding: 0px 0 34px;
}
.responsiveSidebar .headerLinks ul {
display: block;
}
.responsiveSidebar .headerLinks ul li {
width: 100%;
float: left;
margin: 0 0 29px;
}
.headerLinks {
float: left;
width: 100%;
}
.togglebuttonrespo {
float: left;
height: 22px;
width: 25px;
position: absolute;
right: 0px;
cursor: pointer;
top: 15px;
}
.togglebuttonrespo span {
background-color: #333;
height: 3px;
width: 100%;
border-radius: 4px;
}
.togglebuttonrespo span:first-child {
position: absolute;
top: 0;
}
.togglebuttonrespo span:nth-child(2) {
position: absolute;
left: 0;
top: 9px;
}
.togglebuttonrespo span:last-child {
position: absolute;
bottom: 0;
left: 0;
}
.responsiveSidebar .headerLinks ul {
display: block;
padding: 0px;
margin: 0px;
}
.responsiveSidebar .headerLinks ul li {
width: 100%;
float: left;
margin: 0 0 29px;
list-style: none;
}
.responsiveSidebar .headerLinks ul li a {
font-size: 20px;
padding: 5px 30px;
text-decoration: none;
color: #333;
}
.togglebuttonrespo span:first-child {
position: absolute;
top: 0;
left: 0;
}
.loginwrapsbtn {
align-items: center;
display: flex;
justify-content: center;
line-height: normal;
vertical-align: middle;
}
.headeraccounts h2, .loginwrapsbtn {
cursor: pointer;
float: left;
width: auto;
}
.loginwrapsbtn h5 {
color: #000;
font-size: 19px;
margin: 0 12px 0 0;
width: auto;
}
.togglebuttonrespo span:nth-child(2) {
position: absolute;
left: 0;
top: 9px;
}
.togglebuttonrespo span:last-child {
position: absolute;
bottom: 0;
left: 0;
}
.colmn-model {
background: transparent;
width: 25px;
border: 0px;
position: absolute;
right: 16px;
top: 15px;
}
.colmn-model svg {
fill: #fff;
}
.selectAccountssct {
height: 57px;
padding: 10px 20px;
border: 1px solid #333;
margin-bottom: 20px;
-webkit-appearance: none;
-moz-appearance: none;
appearance: none;
font-size: 22px;
outline: none;
background-image: url("../img/careticons.png");
background-repeat: no-repeat;
background-position: 97% center;
background-size: 19px;
}
.responsiveSidebar.active {
transform: translateX(0%);
-webkit-transition: 0.5s;
-moz-transition: 0.5s;
-o-transition: 0.5s;
transition: 0.5s;
}
#responsiveSidebarMain .togglebuttonrespo {
top: 23px;
right: 24px;
}
#responsiveSidebarMain .togglebuttonrespo span:nth-child(2) {
display: none;
}
#responsiveSidebarMain .togglebuttonrespo span:first-child {
transform: rotate(45deg);
top: 12px;
}
#responsiveSidebarMain .togglebuttonrespo span:last-child {
transform: rotate(-45deg);
top: 12px;
}
#responsiveSidebarMain .togglebuttonrespo span {
    background-color: #333;
}
a.dropdwonsidebarMenua {
display: flex;
justify-content: space-between;
}
a.dropdwonsidebarMenua svg {
width: 16px;
float: right;
}
a.dropdwonsidebarMenua.true svg {
transform: rotate(-180deg);
}
ul.dropdwonsidebar {
padding: 15px 20px 0px !important;
}
ul.dropdwonsidebar li a {
font-size: 17px !important;
}
ul.dropdwonsidebar li:last-child {
margin-bottom: 0px !important;
}
/* responsive sidebar css end here */
    
/* Loader start here */
.loaderwrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background: rgba(255,255,255, 0.9);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loaderwrapper .loader {
    border: 5px solid #070000;
    border-radius: 50%;
    border-top: 5px solid transparent;
    width: 100px;
    height: 100px;
    animation: spin 0.9s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
/* Loader end here */

.sitenotavailbale {
float: left;
width: 100%;
text-align: center;
font-size: 25px;
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
font-family: sans-serif;
}
.sitenotavailbale small {
    font-size: 14px;
}

.capturebtnwrap {float: left;width: 100%;display: flex;align-items: center;justify-content: center;column-gap: 17px;}
.capturebtnwrap .btn {width: 100%;border-radius: 3px;font-size: 14px;padding: 8px 10px;}
.capturebtnwrap .capturebtn, .capturebtnwrap .capturebtn:hover, .capturebtnwrap .capturebtn:focus {
/* background-color: rgb(218,57,43);
border-color: rgb(218,57,43); */
border-radius: 6px;
}
.loginbtn .spinner-border {
    float: none;
    width: 24px;
    height: 24px;
    border-width: 3px;
    margin: 0px;
}
.capturebtnwrap .Browsebtn, .capturebtnwrap .Browsebtn:hover, .capturebtnwrap .Browsebtn:focus {background-color: #fff;border-color: #fff;color: #333;}
.capturebtnwrap .capturebtn img {filter: invert(100%) sepia(11%) saturate(7500%) hue-rotate(171deg) brightness(113%) contrast(109%);}
.capturebtnwrap .capturebtn {width: 100% !important;}
.capturebtnwrap .Browsebtn {width: 100% !important;position: relative;overflow: hidden;color: #333 !important;color: #333 !important;border: 1px solid #333 !important;border-radius: 6px;}
.capturebtnwrap .Browsebtn input[type="file"] {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;visibility: hidden;}
.capturebtnwrap .btn img {width: 21px;margin: 0px 8px 0 0;}
.captureImagesWraps {float: left;width: 100%;position: relative;height: 170px;}
.captureImagesWraps .imageavatarcapture {width: 100%;height: 100%;object-fit: contain;object-position: center;background-color: #f4f4f4;}
.captureImagesWraps .remove_Image {float: left;width: 20px;position: absolute;right: 7px;top: 7px;cursor: pointer;}
.capturebtnwrap {float: left;width: 100%;display: flex;align-items: center;justify-content: center;column-gap: 17px;}
.capturebtnwrap .Browsebtn, .capturebtnwrap .Browsebtn:hover, .capturebtnwrap .Browsebtn:focus {background-color: #fff;border-color: #fff;color: #333;}
.capturebtnwrap .Browsebtn input[type="file"] {float: left;width: 100%;height: 100%;position: absolute;left: 0;top: 0;visibility: hidden;}

/* allow camera access css  */
.AllowCammeraWrapper { float: left; width: 100%; height: 100vh; display: flex; justify-content: center; align-items: center; flex-direction: column; margin: 0 auto; } 
.AllowCammeraWrapper .AllowCammeras { width: 400px; height: auto; float: none; padding: 31px 30px; background-color: #fff; } 
.AllowCammeraWrapper button { background-color: #c0422f; border: 0px; width: auto; padding: 8px 20px; font-size: 14px; color: #fff; float: right; } 
.ImageViewPreviewModal.captureImage { z-index: 9999; } 
.AllowCammeraWrapper p img { width: 26px; } 
.AllowCammeraWrapper h4 { float: left; width: 100%;font-size: 27px; font-weight: 100; margin: 0 0 15px; } 
.AllowCammeraWrapper p { float: left; width: 100%; font-size: 14px; color: #7c7c7c; text-align: left; letter-spacing: 0.7px; line-height: 24px; margin: 0px 0px 10px; }
/* allow camera access css  */


.Browsebtn {
border: 1px solid #e0e0e5 !important;
float: left;
width: 100%;
position: relative;
overflow: hidden;
text-align: center;
padding: 6px 8px;
margin: 0px !important;
}
.Browsebtn input[type="file"] {
float: left;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
opacity: 0;
cursor: pointer;
}
.Browsebtn img {
width: 25px;
}

/* image preview modal start here */
.ImageViewPreviewModal { float: left; width: 100%; height: 100%; background-color: rgba(0,0,0,0.8); position: fixed; top: 0; left: 0; z-index: 9999; } 
.imageViewMain { float: none; width: 65%; margin: 80px auto 10px; height: calc(100vh - 30%); text-align: center; display: flex; justify-content: center; align-items: center; } 
.imageViewsTabs { float: none; width: 65%; display: flex; position: absolute; bottom: 15px; left: 0; right: 0; margin: 0 auto; } 
.imageViewsTabs .AudioTabs { float: none; width: 90px !important; height: 90px; position: relative; overflow: hidden; border-radius: 10px; cursor: pointer; background-color: #E8EEFC; display: flex; align-items: center; justify-content: center; } 
.imageViewsTabs .AudioTabs img { float: none; width: 40px; height: 40px; object-fit: contain; object-position: center; margin: 25px auto 0; } 
.imageViewsTabs .imagevideoTabs { float: none; width: 90px !important; height: 90px; position: relative; overflow: hidden; border-radius: 10px; cursor: pointer; } 
.imageViewsTabs .imagevideoTabs img { float: left; width: 100%; height: 100%; object-fit: cover; object-position: center; } 
.imageViewsTabs .imagevideoTabs .videoPLybtn { float: left; width: 45px; height: 45px; position: absolute; right: 0; top: 24px; left: 0; margin: 0 auto; } 
.imageViewMain img { float: left; width: 100%; height: 100%; object-fit: contain; object-position: center; } 
.imageViewMain video { float: left; width: 100%; height: 100%; object-fit: contain; object-position: center; } 
.ImageViewPreviewModal .controlbars { float: left; width: 220px; display: flex; position: absolute; right: 20px; top: 20px; } 
.ImageViewPreviewModal .controlbars img {filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(304deg) brightness(107%) contrast(101%);cursor: pointer; width: 28px; height: 28px; margin: 0 auto; float: none; } 
.ImageViewPreviewModal .controlbars .Tooltip-Wrapper { text-align: center; }
.ImageViewPreviewModal .controlbars .user_profile_popup{color: white;display: flex;flex-direction: row;align-items: center;width: auto;font-size: 14px;height: 100%;z-index: 1;gap: 10px;}
.ImageViewPreviewModal .controlbars .user_profile_popup div{display: flex;flex-direction: row;align-items: center;flex-direction: column;}
.ImageViewPreviewModal .controlbars .user_profile_popup img{height: 40px!important;width: 40px!important;border-radius: 50%;}
.ImageViewPreviewModal .controlbars .user_profile_popup h4{font-size: 14px;margin-bottom: 0pc!important;}
.ImageViewPreviewModal .controlbars .user_profile_popup p{margin-bottom: 0pc!important;font-size: 12px;}
/* Profile Preview */
.ImageViewPreviewModal.Profile_view { float: left; width: 100%; height: 100%; background-color: rgba(0,0,0,0.8); position: fixed; top: 0; left: 0; z-index: 99999 !important; } 
.ImageViewPreviewModal.Profile_view .controlbars { float: left; width: 170px; display: flex; position: absolute; right: 20px; top: 20px; } 
.ImageViewPreviewModal.Profile_view .controlbars img { cursor: pointer; width: 28px!important; height: 28px!important; margin: 0 auto; float: none; } 
.ImageViewPreviewModal.Profile_view .controlbars .Tooltip-Wrapper { text-align: center; } 
/* Profile Preview */
.ImageViewPreviewModal.Save_popup_view .imageViewMain{ height: calc(100vh - 6rem);margin: 2rem auto 2rem;} 
.ImageViewPreviewModal.Profile_view .imageViewMain{ display: flex; align-items: center; justify-content: center; z-index: 1; visibility: visible; position: relative;height: calc(100vh - 6rem);margin: 2rem auto 2rem;} 
.ImageViewPreviewModal.Profile_view .imageViewMain img{ height: 100%!important; width: auto!important; border-radius: 0px; text-align: center; z-index: 1; float: none; z-index: 100; } 
.imagevideoTabs.active::after { content: ""; float: left; width: 100%; height: 100%; border: 5px solid #3874B9; position: absolute; top: 0; left: 0; } 
.AudioTabs.active::after { content: ""; float: left; width: 100%; height: 100%; border: 5px solid #3874B9; position: absolute; top: 0; left: 0; } 
.imageViewsTabs .slick-slider { margin-bottom: 0px; }
/* image preview modal end here */

/* image capture css start here */ 
.imageViewMain.imagecapturemain { position: relative; height: calc(100vh - 23%) !important; } 
.imageViewMain.imagecapturemain .btnGroupswrapper {float: right;width: 100%;position: absolute;z-index: 99;text-align: center;display: flex;margin: 0 auto;right: 0;justify-content: center;align-items: flex-end;bottom: 15px;} 
.imageViewMain.imagecapturemain .campturebtncmera { width: 70px; height: 70px; background-color: #c0422f; border-radius: 20px; position: relative; cursor: pointer; box-shadow: 0px 3px 9px -2px #c0422f; margin: 0px 19px;} 
.imageViewMain.imagecapturemain .campturImagesend { width: 60px; height: 60px; background-color: #c0422f; border-radius: 20px; position: relative; cursor: pointer; box-shadow: 0px 3px 9px -2px #c0422f; margin: 0px 19px; } 
.imageViewMain.imagecapturemain .camptureImageDelete { width: 60px; height: 60px; background-color: #fff; border-radius: 20px; position: relative; cursor: pointer; box-shadow: 0px 3px 9px -2px #fff; } 
.btnGroupswrapper .Tooltip-Wrapper { width: auto; } 
.imageViewMain.imagecapturemain .camptureImageDelete img { width: 27px; height: 27px; float: none; margin: 17px auto 0; cursor: pointer; }
.imageViewMain.imagecapturemain .campturImagesend img { width: 30px; height: 30px; float: none; margin: 15px auto 0; cursor: pointer; filter: invert(87%) sepia(89%) saturate(0%) hue-rotate(184deg) brightness(110%) contrast(97%); } 
.imageViewMain.imagecapturemain .campturebtncmera img{ width: 35px; height: 35px; float: none; margin: 17px auto 0; cursor: pointer; filter: invert(87%) sepia(89%) saturate(0%) hue-rotate(184deg) brightness(110%) contrast(97%); } 
/* image capture css end here */ 


.loader_Wrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    background: rgb(0 0 0 / 70%);
    backdrop-filter: blur(2px);
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px 20px;
    align-items: center;
    justify-content: center;
}
.loader_main {
    float: left;
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 30px 25px;
    border-radius: 20px;
}

.loader_Wrapper .spinner-border {
    width: 80px;
    height: 80px;
}

.loader_Wrapper h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 29px;
    margin: 25px 0 0;
}

/* multi select tag custom css */
.form-groupfiled .select__control {
-webkit-box-flex-wrap: nowrap;
-webkit-flex-wrap: nowrap;
-ms-flex-wrap: nowrap;
flex-wrap: nowrap;
}
.form-groupfiled .select__control .select__indicators {
width: 70px;
}
/* multi select tag custom css */
.pageisLoading {
float: left;
width: 100%;
height: 100%;
position: fixed;
left: 0;
top: 0;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(0,0,0,0.3);
z-index: 99999;
flex-direction: column;
}
.pageisLoading img {
width: 80px;
}
.loginwitemilformwrps .form-group label .passwordnotewrapper {
float: none;
width: auto;
position: relative;
display: inline-block;
margin: 0 0px 0px 6px;
}
.loginwitemilformwrps .form-group label .passwordnotewrapper img{
width: 13px;
}
.loginwitemilformwrps .form-group label .passwordnotewrapper ol {
left: 0;
right: auto;
}



/* error message design  */
.Error_message_wraps {
    float: left;
    width: 100%;
    position: relative;
}

.Error_message_wraps h4 {
    font-size: 22px;
    font-weight: 600;
}

.Error_message_wraps small {
    font-size: 14px;
    margin: 0 0 10px;
}
.Error_message_wraps .accordion .accordion-item {
    border-radius: 10px;
    border: 1px solid #ddd;
    outline: none !important;
    box-shadow: none !important;
    position: relative;
}
.Error_message_wraps .accordion .accordion-item button{
    outline: none !important;
    box-shadow: none !important;
}
.Error_message_wraps .accordion {
    float: left;
    width: 100%;
    border-radius: 0px;
    margin-top: 10px;
}
.AttepsshowError {
    width: auto;
    float: none;
    margin: 0 auto;
    background-color: #fff;
    display: table;
    padding: 8px 20px;
    font-size: 14px;
    border-radius: 30px;
}
.Error_message_wraps .accordion h3 {
    float: left;
    width: 100%;
    font-size: 14px;
    margin: 12px 0px 4px;
    text-align: left;
    padding-left: 35px;
}
.collpseheader {
    float: left;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 15px;
}
.spoofingDetectionModel.spoofingDetectionModelActive {
    /* align-items: flex-start; */
}
.collpseheader img {
    width: 22px;
    margin: 9px 10px 0px 0px;
}
.Error_message_wraps .accordion button.accordion-button {
    width: 100%;
    background-color: transparent;
    color: #333;
    border-radius: 0px !important;
    border-bottom: 1px solid #ddd !important;
    padding: 10px 17px;
    font-size: 14px;
}
.Error_message_wraps .accordion .accordion-item .accordion-collapse {
    /* position: absolute;
    left: 0;
    top: 45px;
    z-index: 99;
    box-shadow: 0px 4px 15px -3px #ccc; */
}
.Error_message_wraps .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 13px 17px;
    font-size: 13px;
    text-align: left;
    /* border-radius: 10px;
    background-color: #fff;
    border: 1px solid #ddd; */
}
.spoofingDetectionModel.spoofingDetectionModelActive .spoofingDetectionModelCONTEND {
    width: 840px;
    background-color: #f5f5f5;
    border-color: #c5c5c5;
    border-radius: 14px;
}
/* error message design  */

/* card details css  */
.card_details_wrapper {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
    padding: 10px 15px;
}
.card_details_wrapper #PaymentForm {
    display: flex;
    column-gap: 20px;
}
.card_details_wrapper #PaymentForm .rccs__number, 
.card_details_wrapper #PaymentForm .rccs__name,
.card_details_wrapper #PaymentForm .rccs__chip {
    left: 5%;
}
.card_details_wrapper #PaymentForm .rccs__expiry {
    right: 9px !important;
    width: 60px;
    text-align: center;
}
#PaymentForm .form-group {
    margin: 0 0 12px;
}
#PaymentForm .form-group input{
    outline: none !important;
}
.card_details_wrapper_main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 25px;
}
.cards_buttons_wraps {
    float: left;
    width: auto;
    column-gap: 13px;
    display: flex;
}
.card_details_wrapper_main .rccs {
    margin: initial !important;
}
.discardbtnUpdate {
    /* display: flex;
    font-size: 19px;
    border-radius: 10px;
    padding: 8px 14px;
    align-items: center;
    justify-content: center; */
}
.discardbtnUpdate img {
    width: 23px;
    margin: 0px 8px 0px 0px;
}
#PaymentForm .react-datepicker-popper {
    width: 300px;
}
#PaymentForm .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    left: -40px !important;
    right: 0 !important;
}
.card_no_title {
    font-size: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
    margin: 20px 0 0;
}
.card_no_title img {
    width: 37px;
}
/* card details css  */

/* table css start here */
tbody, td, tfoot, th, thead, tr {float: none;width: auto;}
.gameListing {float: left;width: 100%;}
.table_set {float: left;width: 100%;max-height: 400px;overflow: auto;}
.gameListing thead tr th {font-size: 13px;}
.gameListing tbody tr td {font-size: 13px;}
.gameListing h4{font-family: 'suez_oneregular';margin-bottom: 20px;}
.label_status {
    float: left;
    width: auto;
    padding: 5px 7px;
    font-size: 12px;
    line-height: normal;
    font-weight: 600;
    border-radius: 4px;
}
.table_set tbody tr td{
    background: transparent !important;
    --bs-table-accent-bg: transparent !important;
}
.table_set tbody tr:hover {
    background-color: #eee !important;
    --bs-table-accent-bg: #eee !important;
}
.label_status.success {background-color: rgba(47,179,68, 0.1);color: rgba(47,179,68);}
.label_status.error {background-color: rgba(214,57,57, 0.1);color: rgba(214,57,57);}
.label_status.primary {background-color: rgba(13, 110, 253, 0.1);color: rgb(13, 110, 253);}
.searchBoxwithbtn {
float: left;
width: 260px;
position: relative;
}
.searchBoxwithbtn .form-control {
border: 1px solid #ddd;
box-shadow: none !important;
padding: 7px 35px 7px 10px;
border-radius: 6px;
height: 36px;
font-size: 13px;
}
.searchBoxwithbtn .icon-tabler-search {
float: right;
width: auto;
position: absolute;
top: 8px;
right: 9px;
}
.searchBoxwithbtn .searchclear {
width: 13px;
height: 13px;
position: absolute;
top: 11px;
right: 9px;
cursor: pointer;
}
.card-header-New {
float: left;
margin: 0 0 15px;
width: 100%;
}
.card-header-right {
float: right;
width: auto;
}
.card-header-right select:first-child {
margin-right: 10px;
}
.card-header-right select {
box-shadow: none !important;
}
.activestatusselet, .userLimitselect {
float: left;
width: 90px;
height: 36px;
border: 1px solid #ddd !important;
background-image: url("../img/selectarrow.svg");
background-size: 13px;
background-repeat: no-repeat;
background-position: 91% center;
cursor: pointer;
}
.paginationCustom li {
    width: auto;
}
.paginationCustom {float: left;width: 100%;padding: 0px;margin: 15px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: #014087;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;}
.paginationCustom #rpb-pagination {justify-content: end;}
/* table css end here */

.statusPendingModel {
    float: left;
    width: 470px;
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 20%;
    z-index: 9999;
    margin: 0 auto;
    display: table;
    right: 0;
    padding: 20px 25px;
    border-radius: 30px;
    text-align: center;
}
.statusPendingModel h1 {
    float: left;
    width: 100%;
    font-size: 20px;
    text-align: center;
    line-height: 31px;
    margin-bottom: 30px;
}
.statusPendingModel h1 b {
    width: auto;
    float: none;
    color: #2b074d;
}
.request_id_pass {
    font-size: 14px;
    padding: 12px 10px;
    border: 1px solid #ccc;
    line-height: normal;
    float: left;
    width: 100%;
    margin: 8px 0 8px;
}
.statusPendingModel img {
    width: 80px;
    margin: 0 auto 15px;
    float: none;
    display: table;
}
.statusPendingModel .btn, .statusPendingModel .btn:focus {
    background-color: #da392b;
    border-color: #da392b;
    border-radius: 10px;
    color: #fff;
    float: none;
    font-size: 15px;
    margin: 0 auto;
    padding: 6px 37px;
    width: auto;
}
.table_set .userAvatar .previewImage {
    background-color: #eee;
    border-radius: 6px;
    height: 60px;
    object-fit: contain;
    width: 60px;
}
.table_set .previewImage img {
    float: left;
    height: 100%;
    object-fit: contain;
    object-position: center;
    width: 100%;
}

/* pagination css start here */
.paginationwraps {
    float: left;
    width: 100%;
    margin: 13px 0px 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 9px;
}
.paginationwraps .btn {
    width: 29px;
    height: 29px;
    min-width: 29px;
    font-size: 14px;
    background-color: #014087;
    color: #fff;
    text-align: center;
    padding: 4px;
}
.paginationwraps .btn svg {
    margin: 0px;
}
.cuurentpagedetails {
    float: left;
    width: auto;
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.cuurentpagedetails select {
    height: 29px;
    padding: 2px 15px 2px 6px;
    box-shadow: none !important;
    background-image: url("../img/select-arrow.png");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 90% center;
    cursor: pointer;
}
#focusLabel {
    float: left;
    width: auto;
    background-color: #333;
    color: #fff;
    padding: 5px 11px;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: -32px;
    border-radius: 8px;
}
/* pagination css end here */
iframe {
    overflow: auto;
}
.iframPayment {
    float: left;
    width: 500px;
    min-height: 410px;
    position: fixed;
    padding: 15px 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: auto;
    background-color: #fff;
    box-shadow: 0px 0px 16px -2px #565656cc;
    border-radius: 15px;
    z-index: 9999;
}
.iframPayment h4 {
    float: left;
    width: 100%;
    font-family: 'suez_oneregular';
    text-align: center;
    font-size: 29px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    padding: 0 0 10px;
}
#i4go_cardholderName-error {
    display: block !important;
}

#i4go_cardholderName-error {
    display: block !important;
}
.optional-field {

}
div#i4goFrame {
    height: 300px;
    float: left;
    width: 100%;
    position: relative;
}
/* .choose_card_method {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.choose_card_method {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #fff;
}
.choose_card_method .selectPayment {
    width: 130px;
    height: 90px;
    float: left;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    position: relative;
}
.choose_card_method .selectPayment.active::after {
    content: "";
    float: left;
    width: 27px;
    height: 27px;
    position: absolute;
    right: -8px;
    top: -8px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/Selectcheck.png");
}
.choose_card_method .selectPayment.active::before {
    content: "";
    float: left;
    width: 100%;
    height: 100%;
    border: 4px solid #2ad352;
    position: absolute;
    border-radius: 15px;
    left: 0;
    top: 0;
}
.choose_card_method .selectPayment.error::after {
    content: "";
    float: left;
    width: 27px;
    height: 27px;
    position: absolute;
    right: -8px;
    top: -8px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../img/close_mark.png");
}
.choose_card_method .selectPayment.error::before {
    content: "";
    float: left;
    width: 100%;
    height: 100%;
    border: 4px solid rgb(218,57,43);
    position: absolute;
    border-radius: 15px;
    left: 0;
    top: 0;
}
.medatoryErrorMessage {
    color: #f84646;
    float: none;
    font-size: 14px;
    width: 95%;
    padding: 10px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    column-gap: 3px;
}
.choose_card_method .selectPayment input {
    visibility: hidden;
    height: 100%;
    width: 100%;
    float: left;
    position: absolute;
    left: 0;
    top: 0;
}
.medatoryErrorMessage .btn {
    background-color: #DBAD47;
    color: #fff;
    width: auto;
    float: right;
    font-size: 12px;
}
.selectPayment img {
    float: none;
    width: 35px;
    height: 35px;
    background: transparent;
    object-fit: contain;
    object-position: center;
    margin: 0 0 5px;
}
.reedem_wrapper {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.list-style-lower-alpha{list-style: lower-alpha!important;}
input, optgroup, select, textarea{font-family: 'helveticaregular'!important;}


.crypto-container {
    margin-bottom: 20px;
  }
  .crypto-container.scrollable {
    overflow-y: scroll; /* Enable scroll when length is 9 or more */
  }
  .scrollable-grid {
    max-height: 230px; /* Adjust the height according to your design */
    overflow-y: scroll; /* Enable scrolling for the grid only */
  }
  
  
  .crypto-container h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .crypto-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 10px; 
  }
  
  .crypto-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    min-width: 170px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border 0.1s ease-in-out;
    cursor: pointer;


  }
  .crypto-card:hover {
    border-color: #aaa;
  }
    
  .crypto-logo {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
  
  .crypto-details {
    display: flex;
    flex-direction: column;
  }  
  .crypto-amount {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .crypto-network {
    font-size: 12px;
    color: #777;
    text-transform: capitalize;
  }
  .crypto-heading{
    font-size: 18px;
  margin-bottom: 20px;
  text-align: start;
  color: #333;

  }
  .crypto-card.selected {
    border: 2px solid rgb(0, 191, 255); /* Highlight the selected card */
  }
  
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
  }
  .loading_spinner {
    border: 2px solid #F3F3F3;
    border-top: 2px solid #0452FA;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation: spin 0.7s linear infinite;
    margin-left: 4px;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }